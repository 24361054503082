.cls-1 {
  fill: #373a36;
}
.cls-2 {
  fill: #0957c3;
}
.dessin {
  fill: transparent;
  transition: fill 1s;
  transition-delay: 1s;
}
.fill-grappe {
  fill: #0957c3;
}
html {
  height: 100vh;
  width: 100vw;
}
#svg {
  position: absolute;
  /* top: 40vh;
  left: 40vw; */
  height: 20%;
  transition: transform 1.5s, opacity 1.5s;
}
.svgMove {
  transform: translate(0, -40vh);
  opacity: 0;
}
.dessin {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash 3s linear forwards;
}
@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}
.fondu {
  animation: fadein 2s;
  -moz-animation: fadein 2s; /* Firefox */
  -webkit-animation: fadein 2s; /* Safari et Chrome */
  -o-animation: fadein 2s; /* Opera */
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadein {
  /* sur Firefox */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fadein {
  /* sur Safari et Chrome */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fadein {
  /* sur Opera */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
